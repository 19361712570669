import React, { useRef } from "react";

import { base } from "./AnimatedSvg.module.scss";

import { combine } from "../utils/classNames";
import { useInView } from "../utils/hooks";

const AnimatedSvg = props => {
  const { graphics, className } = props;

  const ref = useRef();
  const inView = useInView(ref);

  return (
    <div className={combine(base, className, inView ? "--in-view" : "")}>
      {graphics.map((graphic, index) => (
        <div
          key={index}
          ref={ref}
          style={{ position: "absolute", bottom: graphic.y + "rem", left: graphic.x + "rem" }}
        >
          <img
            src={graphic.image}
            alt=""
            style={{
              animationDelay: graphic.delay + "s",
              height: graphic.height && graphic.height + "rem",
              width: graphic.width && graphic.width + "rem",
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default AnimatedSvg;
