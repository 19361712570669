import React from "react";

import { base, image, icon } from "./ActivationCategory.module.scss";

import { combine, getClassNameModifiers } from "../utils/classNames";

const ActivationCategory = props => {
  const { className, heading, iconSrc, activationTypes, imageSrc } = props;

  const modifiers = getClassNameModifiers(props, ["color"]);

  return (
    <div className={combine(base, modifiers, className, imageSrc ? "--has-image" : "")}>
      {imageSrc && <img className={image} src={imageSrc} alt="" />}
      {iconSrc && <img className={icon} src={iconSrc} alt="" />}
      <h2>{heading}</h2>
      <ul>
        {activationTypes.map((activationType, index) => (
          <li key={index}>{activationType}</li>
        ))}
      </ul>
    </div>
  );
};

export default ActivationCategory;
