import React from "react";

import {
  base,
  activationCategoriesContainer,
  activationCategory,
  buttonContainer,
} from "./VerticalCategoriesSection.module.scss";

import ActivationCategory from "./ActivationCategory";
import Button from "../components/Button";

import { combine, getClassNameModifiers } from "../utils/classNames";

const VerticalCategoriesSection = props => {
  const { className, heading, subHeading, categories, noCTA } = props;

  const modifiers = getClassNameModifiers(props, ["theme"]);

  return (
    <section className={combine(base, modifiers, className)}>
      <h2>{heading}</h2>
      <p>{subHeading}</p>
      <div className={activationCategoriesContainer}>
        {categories.map((category, index) => (
          <ActivationCategory
            className={combine(activationCategory, `--${categories.length}-children`)}
            key={index}
            heading={category.label}
            iconSrc={category.iconSrc}
            activationTypes={category.activationTypes}
            color={category.color}
          />
        ))}
      </div>
      {!noCTA && (
        <div className={buttonContainer}>
          <Button color="navy" href="/book-a-demo">
            Start today
          </Button>
          <Button isSecondary color="navy" href="/book-a-demo">
            Schedule a demo
          </Button>
        </div>
      )}
    </section>
  );
};

export default VerticalCategoriesSection;
