import React from "react";

import { base } from "./Checklist.module.scss";

import { combine } from "../utils/classNames";

const Checklist = props => {
  const { items, className } = props;

  return (
    <div className={combine(base, className)}>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <img src="/images/checkmark.svg" alt="Checkmark" />
            <p>{item}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Checklist;
