import React from "react";

import Button from "../components/Button";

const CTAButtons = props => {
  const { className, buttons, style } = props;

  if (!buttons || buttons.length === 0) {
    return null;
  }

  return (
    <div className={className} style={style}>
      <Button color={buttons[0].color || "navy"} href={buttons[0].href}>
        {buttons[0].label}
      </Button>
      {buttons[1] && (
        <Button isSecondary color={buttons[1].color || "navy"} href={buttons[1].href}>
          {buttons[1].label}
        </Button>
      )}
    </div>
  );
};

export default CTAButtons;
