import React, { useRef, useEffect } from "react";

import { base, viewport, lineBreaker } from "./LogoCarousel.module.scss";

import { combine, getClassNameModifiers } from "../utils/classNames";
import { useMediaQuery } from "../utils/hooks";

const LogoCarousel = props => {
  const { className, logos, height } = props;

  const modifiers = getClassNameModifiers(props, ["backgroundColor"]);

  const viewPort = useRef(null);
  const isMobile = useMediaQuery("(max-width: 575px)");

  const getSum = logos => logos.reduce((sum, obj) => sum + Number(obj.width), 0);

  const margin = 4.625;
  const totalWidth = getSum(logos) + logos.length * margin;

  const keyframes = [
    { transform: `translateX(0)`, easing: "ease" },
    ...logos.map((logo, index) => ({
      transform: `translateX(-${getSum(logos.slice(0, index + 1)) + (index + 1) * margin}rem)`,
      easing: "ease",
    })),
  ];

  const order = (order, index) => (index >= logos.length ? logos.length + order : order);

  useEffect(() => {
    const animation = viewPort.current.animate(keyframes, {
      duration: isMobile ? Infinity : logos.length * 2000,
      iterations: Infinity,
    });
  });

  // For desktop carousel, repeating the logos once gives the illusion of infinite scroll
  const logosToRender = isMobile ? logos : [...logos, ...logos];

  return (
    <div
      className={combine(base, modifiers, className)}
      style={{
        width: isMobile ? "100vw" : totalWidth + "rem",
        height: isMobile ? null : height + "rem",
      }}
    >
      <ol className={viewport} ref={viewPort}>
        {isMobile ? (
          <>
            {logosToRender.slice(0, 3).map((logo, index) => (
              <li key={index}>
                <img style={{ width: logo.width + "rem" }} src={logo.src} alt={logo.alt} />
              </li>
            ))}
            <li className={lineBreaker}></li>
            {logosToRender.slice(3, 6).map((logo, index) => (
              <li key={index}>
                <img style={{ width: logo.width + "rem" }} src={logo.src} alt={logo.alt} />
              </li>
            ))}
          </>
        ) : (
          logosToRender.map((logo, index) => (
            <li key={index}>
              <img style={{ width: logo.width + "rem" }} src={logo.src} alt={logo.alt} />
            </li>
          ))
        )}
      </ol>
    </div>
  );
};

export default LogoCarousel;
